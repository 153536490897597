import * as React from 'react'
import Helmet from 'react-helmet'
import { withPrefix, StaticQuery, graphql } from 'gatsby'
import 'modern-normalize'
import '../../styles/normalize'

import '../../../saas-theme/src/assets/scss/page.scss';

import Navbar from '../Landing/Navbar'
// import SubHeader from '../Landing/Subheader';
import LayoutRoot from '../LayoutRoot'
// import LayoutMain from '../LayoutMain'
import Footer from '../Landing/Footer';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const BlogLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query BlogLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
        >
        <script src={withPrefix('page.min.js')} type="text/javascript" />
          
        </Helmet>
        <Navbar />
        {children}
        <Footer />
      </LayoutRoot>
    )}
  />
)

export default BlogLayout



